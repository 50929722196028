import React, { useState, Fragment } from "react";
import { formatDistanceToNow } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Link, useHistory } from "react-router-dom";
import Trash from "../../../svg/trash";
import Alert from "../../../svg/alert";
import useLocalStorage from "../../../hooks/useLocalStorage";
import { deleteLead } from "../../../request";
import { toast } from "react-toastify";
import UniqueValue from "../../../svg/uniqueValue";
import RecurringValue from "../../../svg/recurringValue";
import { revalidateToken } from "../../../utils";
import BusinessDetailModal from "../../Modal/BusinessDetailModal";
import { useEffect } from "react";

const StageItem = ({
  lead: {
    pipelineID,
    name,
    customerCompany,
    uniqueValue = 0,
    recurringValue,
    id,
    userUID,
    timeInStage,
    activityStatus,
  },
  type,
  onDragStart,
  modalConfirm,
  handleModalConfirm,
  handleBusinessPageModal,
}) => {
  const [uid] = useLocalStorage("uid");
  const [role] = useLocalStorage("role");
  const history = useHistory();

  const getColor = (status) => {
    console.log(status);
    switch (status) {
      //case "upcoming":
      // return "green";
      case "today":
        return "orange";
      case "overdue":
        return "red";
      default:
        return "white";
    }
  };

  const rounder = (value) => {
    return Math.round(value * 100) / 100;
  };

  const transformValue = (value) => {
    if (value >= 1000000) {
      return `R$ ${rounder(value / 1000000)}M`;
    }
    if (value >= 1000) {
      return `R$ ${rounder(value / 1000)}K`;
    }
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const handleDeleteLead = () =>
    new Promise((resolve, reject) => {
      revalidateToken().then(() => {
        deleteLead(id, pipelineID)
          .then(() => {
            toast.success("Negócio deletado com sucesso");
            history.push("/negocios");
            resolve();
          })
          .catch((error) => {
            if (error.response?.data === "auth/id-token-expired") {
              toast.error(
                "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
              );
              return;
            }
            console.error("Erro: ", error.message, " Status: ", error.code);
            toast.error("Não foi possível deletar a negócio");
            reject();
          });
      });
    });

  const handleModalConfirmSon = (value) => {
    let aux = JSON.parse(JSON.stringify(modalConfirm));
    aux = value;
    handleModalConfirm(aux);
  };

  const handleModalConfirmDeleted = () => {
    if (role !== "manager" && role !== "admin") {
      handleModalConfirmSon({
        status: true,
        hasCancel: false,
        hasConfirm: false,
        message: "Você não tem permissão para excluir um negócio",
      });
    } else {
      handleModalConfirmSon({
        status: true,
        hasCancel: true,
        hasConfirm: true,
        onConfirm: () => handleDeleteLead(),
        message:
          "Excluindo o negócio, todas as atividades serão apagadas. Deseja continuar?",
      });
    }
  };

  console.log(getColor(activityStatus));

  return (
    <Fragment>
      <div
        /*to={`/negocios/${id}`} draggable="false"*/ onClick={() => {
          handleBusinessPageModal(true, id);
        }}
      >
        <>
          {type !== "success" ? (
            <div
              className="itemStage flex card"
              style={{ marginTop: "0" }}
              draggable={true}
              onDragStart={(e) => onDragStart(e, id)}
            >
              <div className="flexGrow flex column">
                {/* <span className="smallMarginTop strong">{name}</span> */}
                <span
                  className="MarginTopZero strong"
                  style={{ wordBreak: "break-word", whiteSpace: "pre-wrap",  marginTop: "0" }}
                >
                  {name.length > 20? name.slice(0,20)+"...": name}
                </span>

                <span
                  className="defaultMarginTop"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <UniqueValue
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                      margin: "2px",
                      display: `${
                        uniqueValue || !recurringValue ? "" : "none"
                      }`,
                    }}
                  />

                  <p
                    style={{
                      marginTop: "4px",
                      display: `${
                        uniqueValue || !recurringValue ? "" : "none"
                      }`,
                    }}
                  >
                    {transformValue(uniqueValue)}
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <RecurringValue
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                      margin: "2px",
                      display: `${recurringValue ? "" : "none"}`,
                    }}
                  />
                  <p
                    style={{
                      marginTop: "4px",
                      display: `${recurringValue ? "" : "none"}`,
                    }}
                  >
                    {transformValue(recurringValue)}
                  </p>
                </span>
              </div>
              <span className="strong itemStageDay flexShrinkNone">
                {formatDistanceToNow(timeInStage, { locale: ptBR })
                  .replaceAll("menos de ", "")
                  .replaceAll("mais de ", "")
                  .replaceAll("cerca de ", "")
                  .replaceAll("quase ", "")}
              </span>
              <div
                style={{
                  display: activityStatus ? "flex" : "none",
                  position: "relative",
                  minWidth: "20px",
                  minHeight: "20px",
                  maxHeight: "20px",
                  maxWidth: "20px",
                  //backgroundColor: getColor(activityStatus),
                  top: "-10px",
                  right: "-10px",
                  margin: "-10px",
                }}
              >
                <Alert fill={getColor(activityStatus)} />
              </div>
            </div>
          ) : (
            <div
              className="itemStage flex card"
              onDragStart={(e) => onDragStart(e, id)}
            >
              <div className="flexGrow flex column">
                <span
                  className="MarginTopZero strong"
                  style={{ marginRigth: "20px" }}
                >
                  {name}
                </span>
                {/* <span className="smallMarginTop strong">{name}</span> */}
                <span
                  className="defaultMarginTop"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <UniqueValue
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                      margin: "2px",
                      display: `${
                        uniqueValue || !recurringValue ? "" : "none"
                      }`,
                    }}
                  />

                  <p
                    style={{
                      marginTop: "4px",
                      display: `${
                        uniqueValue || !recurringValue ? "" : "none"
                      }`,
                    }}
                  >
                    {transformValue(uniqueValue)}
                  </p>
                </span>
                <span
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <RecurringValue
                    style={{
                      width: "20px",
                      height: "20px",
                      verticalAlign: "middle",
                      margin: "2px",
                      display: `${recurringValue ? "" : "none"}`,
                    }}
                  />
                  <p
                    style={{
                      marginTop: "4px",
                      display: `${recurringValue ? "" : "none"}`,
                    }}
                  >
                    {transformValue(recurringValue)}
                  </p>
                </span>
              </div>
              <span className="strong itemStageDay flexShrinkNone">
                {formatDistanceToNow(timeInStage, { locale: ptBR })
                  .replaceAll("menos de ", "")
                  .replaceAll("mais de ", "")
                  .replaceAll("cerca de ", "")
                  .replaceAll("quase ", "")}
              </span>
              <div
                style={{
                  display: activityStatus ? "flex" : "none",
                  position: "relative",
                  minWidth: "20px",
                  minHeight: "20px",
                  maxHeight: "20px",
                  maxWidth: "20px",
                  //backgroundColor: getColor(activityStatus),
                  top: "-10px",
                  right: "-10px",
                  margin: "-10px",
                }}
              >
                <Alert fill={getColor(activityStatus)} />
              </div>
            </div>
          )}
        </>
      </div>
      <Trash
        className="pointer trash"
        fill={
          ((role !== "manager" && role !== "admin") ||
            (role === "admin" && userUID !== uid)) &&
          "#54555799"
        }
        height="15px"
        style={{
          bottom: "22px",
          zIndex: 1,
          position: "relative",
          left: "80%",
        }}
        onClick={() => handleModalConfirmDeleted()}
      />
    </Fragment>
  );
};

export default StageItem;
