import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Activities from "../../components/Activities";
import Comments from "../../components/Comments";
import Events from "../../components/LeadEvents";
import ProgressBar from "../../components/ProgressBar";
import Base from "../../components/Base";
import Button from "../../components/Button";
import Input from "../../components/InputHook";
import InputMasked from "../../components/InputMaskedHook";
import InputCurrency from "../../components/InputCurrency";
import InputHour from "../../components/InputHour";
import Select from "../../components/Select";
import Hr from "../../components/Hr";
import Score from "../../components/Score";
import TextArea from "../../components/TextArea";
import StageHeader from "../../components/Stage/StageHeader";
import Modal from "../../components/Modal";
import Qualified from "../../components/Modal/Qualified";
import Qualification from "../../components/Modal/Qualification";
import QualificationEdit from "../../components/Modal/QualificationEdit";
import Unqualified from "../../components/Modal/Unqualified";
import ContinueLater from "../../components/Modal/ContinueLater";
import Lose from "../../components/Modal/Lose";
import Won from "../../components/Modal/Won";
import Edit from "../../svg/edit";
import BackIcon from "../../svg/backIcon";
import {
  getInformationsByType,
  getAllStagesByPipelineID,
  getLead,
  putLead,
  postActivity,
  getAllActivitiesByLeadID,
  getAllPipelineByCompanyID,
  listMembersByTeamPosition,
  listCustomerCompanies,
  listCustomerContacts,
  listMyLeads,
  postEvent,
  refreshToken,
  getAllUserComments,
  getAllLeadComments,
  postComment,
  getEventsByLeadID,
} from "../../request";
import { dateToMili, currencyToNumber, removeEmptyKeys } from "../../script";
import {
  ACTIVITY_PENDING,
  payloadBusinessValidation,
  payloadActivityValidation,
  ROLE_MANAGER,
  STAGE_SELLER,
  STAGE_PRE_SELLER,
} from "../../utils";
import { toast } from "react-toastify";
import AutoComplete from "../../components/AutoComplete";
import ActivityModal from "../../components/Modal/Activity";
import { revalidateToken } from "../../../src/utils/index";

const BusinessDetailPage = (idlead) => {
  const history = useHistory();
  const [leadID, setLeadID] = useState(idlead.id);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(null);
  const changingList = useSelector((state) => state.activities.loading);

  const [labelScore, setLabelScore] = useState("");
  const [score, setScore] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);
  const [teamMembersQualified, setTeamMembersQualified] = useState([]);
  const [prevFields, setPrevFields] = useState({});
  const [fields, setFields] = useState({
    userUID: "",
    stageID: "",
    memberProfileID: "",
    name: "",
    observation: "",
    leadOrigin: "",
    pain: "",
    uniqueValue: "",
    recurringValue: "",
    expectedDate: "",
    customField: "",
    customerCompany: {},
    customerContact: {},
  });
  const [customFieldLabel, setCustomFieldLabel] = useState("");

  useEffect(() => {
    setLeadID(idlead.id);
  }, [idlead.id]);

  const [note, setNote] = useState({
    leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
    startTime: "",
    activityTypeID: "",
    endTime: "",
    description: "",
    date: ""
  });

  const [companyID, setCompanyID] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);
  const [isQualified, setIsQualified] = useState(null);
  const [qualificationModal, setQualificationModal] = useState(null);
  const [qualificationEditModal, setQualificationEditModal] = useState(null);
  const [qualifiedModal, setQualifiedModal] = useState(null);
  const [unqualifiedModal, setUnqualifiedModal] = useState(null);
  const [continueLaterModal, setContinueLaterModal] = useState(null);
  const [wonModal, setWonModal] = useState(null);
  const [loseModal, setLoseModal] = useState(null);
  const [editPipelineModal, setEditPipelineModal] = useState(null);
  const [result, setResult] = useState("");

  const [pipelineOptions, setPipelineOptions] = useState([]);
  const [segmentsOptions, setSegmentsOptions] = useState([]);
  const [sourcesOptions, setSourcesOptions] = useState([]);
  const [painOptions, setPainOptions] = useState([]);
  const [stageHeaders, setStageHeaders] = useState();
  const [stages, setStages] = useState([]);
  const [selectedPipeline, setSelectedPipeline] = useState();
  const [stageOptions, setStageOptions] = useState([]);
  const [loadingStageOptions, setLoadingStageOptions] = useState(false);
  const [newStage, setNewStage] = useState();

  const [nextActivities, setNextActivities] = useState([]);
  const [historyActivities, setHistoryActivities] = useState([]);

  const [loadingPutLead, setLoadingPutLead] = useState(false);
  const [loadingSelectStage, setLoadingSelectStage] = useState(false);
  const [loadingListActivities, setLoadingListActivities] = useState(false);
  const [loadingPostActivity, setLoadingPostActivity] = useState(false);
  const [reasonOption, setReasonOption] = useState([]);
  const [companiesOptions, setCompaniesOptions] = useState([]);
  const [customerCompanyID, setCustomerCompanyID] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [contactsOptions, setContactsOptions] = useState([]);
  const [customerContactID, setCustomerContactID] = useState("");
  const [customerContactName, setCustomerContactName] = useState("");
  const [segment, setSegment] = useState("");
  const [site, setSite] = useState("");
  const [email, setEmail] = useState("");
  const [phoneMain, setPhoneMain] = useState("");
  const [socialNetwork, setSocialNetwork] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhoneMain, setContactPhoneMain] = useState("");
  const [contactPhoneSecondary, setContactPhoneSecondary] = useState("");
  const [contactWhatsApp, setContactWhatsApp] = useState("");
  const [contactPosition, setContactPosition] = useState("");
  const [isBusinessPage, setBusinessPage] = useState(true);
  const [activityModal, setActivityModal] = useState(null);
  const [activity, setActivity] = useState();
  const [leads, setLeads] = useState([]);
  const [pipelineID, setPipelineID] = useState("");

  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const [displayComments, setDisplayComments] = useState([]);
  const [showAllComments, setShowAllComments] = useState(false);
  const [loadingComments, setLoadingComments] = useState(false);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [showAllEvents, setShowAllEvents] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [events, setEvents] = useState([]);

  const handleOpenModal = () => {
    setIsLoading(true);
    revalidateToken().then(() => {
      getInformationsByType("REASONS_LOSED").then(({ data }) => {
        setReasonOption(data[0].REASONS_LOSED.data);
        setLoseModal(true);
      });
    });
  };

  const getEvents = async (stages) => {
    setLoadingEvents(true);
    getEventsByLeadID(leadID)
      .then(({ data }) => {
        let events = data;
        if (data.length) {
          listMembersByTeamPosition(
            STAGE_PRE_SELLER,
            STAGE_SELLER,
            ROLE_MANAGER
          )
            .then(({ data }) => {
              let members = data;
              setTeamMembers(members);
              setEvents(
                events.map((ev) => {
                  const user = members.find(
                    ({ userUID }) => userUID === ev.userUID
                  );
                  const nStage = ev.newStageID
                    ? stages.find(({ id }) => id === ev.newStageID)
                    : null;

                  let newDescription = ev.description;
                  if (ev.type === "activity") {
                    if (ev.status != "open") {
                      const time = new Date(
                        ev.description.slice(12, 45)
                      ).toLocaleString("en-GB", {
                        hour12: false,
                      });
                      newDescription =
                        ev.description.slice(0, 11) +
                        time +
                        ev.description.slice(75, -1);
                    } else {
                      const time = new Date(
                        ev.description.slice(-62, -1)
                      ).toLocaleString("en-GB", {
                        hour12: false,
                      });
                      newDescription = ev.description.slice(0, -62) + time;
                      console.log(newDescription);
                    }
                  }

                  return {
                    id: ev._id,
                    userName: user ? user.name : "?",
                    newStageName: nStage
                      ? nStage.description
                        ? nStage.description
                        : nStage.step
                      : null,
                    description: newDescription,
                    date: ev.date,
                  };
                })
              );
              setLoadingEvents(false);
            })
            .catch((error) => {
              throw error;
            });
        } else {
          setEvents([]);
          setLoadingEvents(false);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          setLoadingComments(false);
          return;
        }
        setLoadingComments(false);
        toast.error("Não foi possivel recuperar os comentários");
      });
  };

  const getComments = async () => {
    setLoadingComments(true);
    getAllLeadComments(leadID)
      .then(({ data }) => {
        let comms = data;
        if (data.length) {
          listMembersByTeamPosition(
            STAGE_PRE_SELLER,
            STAGE_SELLER,
            ROLE_MANAGER
          )
            .then(({ data }) => {
              let members = data;
              setTeamMembers(members);
              setComments(
                comms.map((comm) => {
                  const user = members.find(
                    ({ userUID }) => userUID === comm.userUID
                  );
                  return {
                    id: comm._id,
                    userName: user ? user.name : "?",
                    ...comm,
                  };
                })
              );
              setLoadingComments(false);
            })
            .catch((error) => {
              throw error;
            });
        } else {
          setComments([]);
          setLoadingComments(false);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          setLoadingComments(false);
          return;
        }
        setLoadingComments(false);
        toast.error("Não foi possivel recuperar os comentários");
      });
  };

  const saveComment = (comment) => {
    const payload = {
      comment: comment,
      leadID: leadID,
    };

    postComment(payload)
      .then(() => {
        toast.success("Comentário salvo com sucesso");
        getComments();
        setComment("");
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível salvar seu comentário");
      });
  };

  const handleShowAllComments = (state) => {
    setShowAllComments(state);
    if (!state) {
      setDisplayComments(
        comments.length >= 3
          ? [comments[0], comments[1], comments[2]]
          : comments
      );
    } else {
      setDisplayComments(comments);
    }
  };

  const handleEditBusinessPipeline = ({pipelineId, stageId}) => {
    handlePutLead({
      ...fields,
      pipelineID: pipelineId,
      stageID: stageId
    });
    const eventData = {
      type: "lead",
      description: "Lead mudou de pipeline",
      leadID: leadID,
      newPipelineID: pipelineID,
      status: "pending",
    };
    postEvent(eventData);
    setSelectedPipeline(null);
    setEditPipelineModal(false);
    setStages(stageOptions);
    if (stageOptions && stageOptions.length) {
      selectStage(stageOptions.find((item) => item.id === stageId));
    }
  };

  const handlePutLead = ({
    expectedDate,
    uniqueValue,
    recurringValue,
    created,
    updated,
    userUID, // NOTE: Não precisa ser enviado
    customerCompany,
    customerContact,
    result,
    pipelineID,
    ...rest
  }) => {
    let complement = {};
    if (customerCompany && prevFields.customerCompany) {
      if (
        customerCompany.companyName !== prevFields.customerCompany.companyName
      ) {
        complement = { ...complement, customerCompany };
      } else {
        complement = { ...complement, customerCompanyID };
      }
    }
    if (customerContact && prevFields.customerContact) {
      if (customerContact.name !== prevFields.customerContact.name) {
        complement = { ...complement, customerContact };
      } else {
        complement = { ...complement, customerContactID };
      }
    }
    setLoadingPutLead(true);

    customerCompany.id = customerCompanyID ? customerCompanyID : "";
    customerCompany._id = customerCompanyID ? customerCompanyID : "";
    customerCompany.companyName = customerCompanyName
      ? customerCompanyName
      : "";
    customerCompany.email = email ? email : "";
    customerCompany.segment = segment ? segment : "";
    customerCompany.phoneMain = phoneMain ? phoneMain : "";
    customerCompany.site = site ? site : "";
    customerCompany.socialNetwork = socialNetwork ? socialNetwork : "";

    customerContact.id = customerContactID ? customerContactID : "";
    customerContact._id = customerContactID ? customerContactID : "";
    customerContact.name = customerContactName ? customerContactName : "";
    customerContact.email = contactEmail ? contactEmail : "";
    customerContact.phoneMain = contactPhoneMain ? contactPhoneMain : "";
    customerContact.phoneSecondary = contactPhoneSecondary
      ? contactPhoneSecondary
      : "";
    customerContact.whatsApp = contactWhatsApp ? contactWhatsApp : "";
    customerContact.position = contactPosition ? contactPosition : "";

    rest.customerCompany = customerCompany;
    rest.customerContact = customerContact;
    rest.customerCompanyID = customerCompanyID;
    rest.customerCompanyName = customerCompanyName;
    rest.customerContactID = customerContactID;
    rest.customerContactName = customerContactName;

    const isValid = payloadBusinessValidation(rest);
    //delete rest.customerCompany
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setLoadingPutLead(false);
      return;
    }

    let payload = {
      id: leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
      _id: leadID,
      expectedDate: dateToMili(expectedDate),
      uniqueValue: currencyToNumber(uniqueValue),
      recurringValue: currencyToNumber(recurringValue),
      pipelineID,
      ...complement,
      ...rest,
    };
    //payload = removeEmptyKeys(payload);
    revalidateToken().then(() => {
      putLead(payload, pipelineID)
        .then(() => {
          toast.success("Negócio atualizado com sucesso");
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível atualizar o negócio");
        })
        .finally(() => {
          setLoadingPutLead(false);
          setIsDisabled(true);
        });
    });
  };

  const handlePostActivity = ({
    date,
    endTime,
    startTime,
    activityTypeID,
    ...rest
  }) => {
    setLoadingPostActivity(true);
    rest.date = date;
    rest.endTime = endTime;
    rest.startTime = startTime;
    rest.activityTypeID = activityTypeID;
    const isValid = payloadActivityValidation(rest);
    if (!isValid.valid) {
      const Msg = () => {
        return (
          <>
            {isValid.errors.map((item) => {
              return (
                <span>
                  {item.message}
                  <br />
                  <br />
                </span>
              );
            })}
          </>
        );
      };
      toast.warning(<Msg />);
      setLoadingPostActivity(false);
      return;
    }
    const dateAux = rest.date.split("/").reverse();
    dateAux.splice(1, 1, +dateAux[1] - 1);
    endTime = new Date(...[...dateAux, ...rest.endTime.split(":")]).getTime();
    startTime = new Date(
      ...[...dateAux, ...rest.startTime.split(":")]
    ).getTime();

    revalidateToken().then(() => {
      postActivity({
        ...rest,
        endTime,
        startTime,
        contactEmail: contactEmail
      })
        .then(() => {
          // console.log({ rest });
          if (rest.activityTypeID == "note") {
            toast.success("Nota cadastrada com sucesso");
          } else {
            toast.success("Atividade cadastrada com sucesso");
            const eventData = {
              type: "activity",
              description: `Atividade do tipo ${rest.activityTypeID} agendada para ${startTime}`,
              activityTypeID: rest.activityTypeID,
              leadID: leadID,
            };
            //postEvent(eventData);
          }
          setNote({
            leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
            startTime: "",
            activityTypeID: "",
            endTime: "",
            description: "",
            date: "",
          });
          handleNoteFields("", "activityTypeID");
          setLoadingPostActivity(false);
          listActivities();
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível cadastrar o negócio");
          setLoadingPostActivity(false);
        })
        .finally(() => {
          handleNoteFields("", "activityTypeID");
          setNote({
            leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
            startTime: "",
            activityTypeID: "",
            endTime: "",
            description: "",
            date: "",
          });
        });
    });
  };

  const handleCommonFields = (value, property) => {
    if (property === "stageID") {
      setFields({
        ...fields,
        [property]: value,
        ["result"]: { ["status"]: "pending" },
      });
    } else {
      setFields({ ...fields, [property]: value });
    }
  };

  const handleNoteFields = (value, property) => {
    setNote((prev) => ({ ...prev, [property]: value }));
  };

  const handleGetLead = (reset = false) => {
    setIsLoading(true);
    revalidateToken().then(() => {
      getLead(
        leadID // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
      )
        .then(
          ({
            data: {
              expectedDate,
              pipelineID,
              companyID,
              areQuestionsAnswered,
              score,
              labelScore,
              result,
              customerCompany,
              customerContact,
              customerCompanyID,
              customerContactID,
              ...rest
            },
          }) => {
            if (expectedDate) {
              expectedDate = new Date(expectedDate).toLocaleDateString("en-GB");
            }
            setIsQualified(areQuestionsAnswered);
            setFields((prev) => ({ ...prev, ...rest, expectedDate }));
            setPrevFields({ ...rest, expectedDate });
            setCompanyID(companyID);
            setScore(score);
            if (result) setResult(result);

            setLabelScore(labelScore);
            listCompanies();
            listContacts();

            setCustomerCompanyName(customerCompany.companyName);
            setCustomerCompanyID(customerCompanyID);
            setSegment(customerCompany?.segment ? customerCompany.segment : "");
            setSite(customerCompany?.site ? customerCompany.site : "");
            setEmail(customerCompany?.email ? customerCompany.email : "");
            setPhoneMain(
              customerCompany?.phoneMain ? customerCompany.phoneMain : ""
            );
            setSocialNetwork(
              customerCompany?.socialNetwork
                ? customerCompany.socialNetwork
                : ""
            );

            setCustomerContactName(
              customerContact?.name ? customerContact.name : ""
            );
            setCustomerContactID(customerContactID ? customerContactID : "");
            setContactEmail(
              customerContact?.email ? customerContact.email : ""
            );
            setContactPhoneMain(
              customerContact?.phoneMain ? customerContact.phoneMain : ""
            );
            setContactPhoneSecondary(
              customerContact?.phoneSecondary
                ? customerContact.phoneSecondary
                : ""
            );
            setContactWhatsApp(
              customerContact?.whatsApp ? customerContact.whatsApp : ""
            );
            setContactPosition(
              customerContact?.position ? customerContact.position : ""
            );
            setPipelineID(pipelineID);

            listMembersByTeamPosition(STAGE_SELLER, ROLE_MANAGER)
              .then(({ data }) => {
                setTeamMembersQualified(data);
              })
              .catch((error) => {
                if (error.response?.data === "auth/id-token-expired") {
                  toast.error(
                    "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                  );
                  return;
                }
                console.error("Erro: ", error.message, " Status: ", error.code);
                toast.error("Não foi possível obter a lista de responsáveis");
              });

            !reset &&
              getAllStagesByPipelineID(pipelineID)
                .then(({ data }) => {
                  setStageOptions(data.sort((a, b) => a.order - b.order));
                  setStages(data.sort((a, b) => a.order - b.order));
                  if (data && data.length) {
                    selectStage(data.find((item) => item.id === rest.stageID));
                  }
                  return data;
                })
                .catch((error) => {
                  if (error.response?.data === "auth/id-token-expired") {
                    toast.error(
                      "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                    );
                    return;
                  }
                  console.error(
                    "Erro: ",
                    error.message,
                    " Status: ",
                    error.code
                  );
                  toast.error("Não foi possível carregar as etapas do funil");
                })
                .then((data) => {
                  getEvents(data);
                });
          }
        )
        .finally(() => setIsLoading(false));
    });
  };

  const listActivities = () => {
    setLoadingListActivities(true);
    revalidateToken().then(() => {
      getAllActivitiesByLeadID({ leadID }) // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
        .then(({ data }) => {
          data = data || [];
          setNextActivities(
            data
              .filter((item) => item.status === ACTIVITY_PENDING)
              .sort((a, b) => a.startTime - b.startTime)
          );
          setHistoryActivities(
            data
              .filter((item) => item.status !== ACTIVITY_PENDING)
              .sort((a, b) => b.startTime - a.startTime)
          );
        })
        .finally(() => {
          if (!leads || leads.length === 0) {
            listMyLeads()
              .then(({ data }) => {
                const sortedData = [...data].filter(
                  (item) => item.id === leadID
                );
                setLeads(sortedData);
              })
              .catch((error) => {
                console.error("Erro: ", error.message, " Status: ", error.code);
                // toast.error('Não foi possível carregar os negócios');
              })
              .finally(() => {
                setIsLoading(false);
                setTimeout(() => setIsLoading(null), 4000);
              });
          }
          setLoadingListActivities(false);
        });
    });
  };

  dispatch({ type: "SET_REFETCH", value: listActivities });

  useEffect(() => {
    revalidateToken().then(() => {
      getInformationsByType("SEGMENTS", "LEAD_SOURCES", "PAIN_POINTS")
        .then((res) => {
          const informations = res.data[0];

          setSegmentsOptions(informations.SEGMENTS.data);
          setSourcesOptions(informations.LEAD_SOURCES.data);
          setPainOptions(informations.PAIN_POINTS.data);
        })
        .catch((error) => {
          if (error.response?.data === "auth/id-token-expired") {
            toast.error(
              "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
            );
            return;
          }
          console.error("Erro: ", error.message, " Status: ", error.code);
          toast.error("Não foi possível obter algumas informações do negócio");
        });
      handleGetLead();
      listActivities();
      getComments();
      let label = JSON.parse(localStorage.getItem("customFields"))?.reduce(
        (field, { key, label }) => {
          if (key === "LEADS") return label;
          return field;
        },
        ""
      );
      if (label) setCustomFieldLabel(label);
      getAllPipelineByCompanyID().then(({ data }) => {
        setPipelineOptions(data);
        //Seta sempre o funil padrão como default pela description "Funil padrão"
        if (data.length > 0) {
          let idDefault = "";
          data.forEach((item) => {
            if (item.isDefault) {
              idDefault = item.id;
              return;
            }
          });
          if (idDefault) {
            setPipelineID(idDefault);
          }
        }
      });
    });
  }, [leadID]);

  useEffect(() => {
    if (qualificationEditModal === false) handleGetLead(true);
  }, [qualificationEditModal]);

  useEffect(() => {
    if (note === false) handleGetLead(true);
  }, [note]);

  function selectStage(selectedStage) {
    revalidateToken().then(() => {
      if (selectedStage) {
        if (selectedStage.step !== "won" && selectedStage.step !== "lost") {
          listMembersByTeamPosition(selectedStage.step, ROLE_MANAGER)
            .then(({ data }) => {
              setTeamMembers(data);
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível obter a lista de responsáveis");
            });
        } else {
          listMembersByTeamPosition(
            STAGE_PRE_SELLER,
            STAGE_SELLER,
            ROLE_MANAGER
          )
            .then(({ data }) => {
              setTeamMembers(data);
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error("Não foi possível obter a lista de responsáveis");
            });
        }

        // setStageID(selectedStage ? selectedStage.id : "");
      }
    });
  }

  function selectStageOnClick(selectedStage) {
    setLoadingPutLead(true);
    revalidateToken().then(() => {
      if (selectedStage) {
        if (selectedStage.step !== "won" && selectedStage.step !== "lost") {
          listMembersByTeamPosition(selectedStage.step, ROLE_MANAGER)
            .then(({ data }) => {
              setTeamMembers(data);
              let payload = {
                id: leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
                stageID: selectedStage.id,
                result: { status: "pending", reason: "" },
              };
              payload = removeEmptyKeys(payload);
              putLead(payload, pipelineID)
                .then(() => {
                  toast.success("Etapa do negócio atualizada com sucesso");
                  const eventData = {
                    type: "lead",
                    description: "Lead mudou de estágio",
                    leadID: leadID,
                    newStageID: selectedStage.id,
                    status: "pending",
                  };
                  postEvent(eventData);
                  listActivities();
                })
                .catch((error) => {
                  if (error.response?.data === "auth/id-token-expired") {
                    toast.error(
                      "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                    );
                    return;
                  }
                  console.error(
                    "Erro: ",
                    error.message,
                    " Status: ",
                    error.code
                  );
                  toast.error("Não foi possível atualizar a etapa do negócio");
                })
                .finally(() => {
                  setLoadingPutLead(false);
                });
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error(
                "Não foi possível carregar os membros da etapa do negócio"
              );
            });
        } else {
          listMembersByTeamPosition(
            STAGE_PRE_SELLER,
            STAGE_SELLER,
            ROLE_MANAGER
          )
            .then(({ data }) => {
              setTeamMembers(data);
              let payload = {
                id: leadID, // TODO: Implementar history.location.pathname.split("/").reverse()[0] quando a tela de funil e detalhes do negócio estiverem linkadas
                stageID: selectedStage.id,
              };
              payload = removeEmptyKeys(payload);
              putLead(payload, pipelineID)
                .then(() => {
                  toast.success("Etapa do negócio atualizada com sucesso");
                  const eventData = {
                    type: "lead",
                    description: "Lead mudou de estágio",
                    leadID: leadID,
                    newStageID: selectedStage.id,
                    status: "pending",
                  };
                  postEvent(eventData);
                })
                .catch((error) => {
                  if (error.response?.data === "auth/id-token-expired") {
                    toast.error(
                      "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                    );
                    return;
                  }
                  console.error(
                    "Erro: ",
                    error.message,
                    " Status: ",
                    error.code
                  );
                  toast.error("Não foi possível atualizar a etapa do negócio");
                })
                .finally(() => {
                  setLoadingPutLead(false);
                });
            })
            .catch((error) => {
              if (error.response?.data === "auth/id-token-expired") {
                toast.error(
                  "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                );
                return;
              }
              console.error("Erro: ", error.message, " Status: ", error.code);
              toast.error(
                "Não foi possível carregar os membros da etapa do negócio"
              );
            });
        }

        // setStageID(selectedStage ? selectedStage.id : "");
      }
    });
  }

  const listContacts = () => {
    listCustomerContacts()
      .then(({ data }) => {
        if (data.length) {
          setContactsOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  const listCompanies = () => {
    listCustomerCompanies()
      .then(({ data }) => {
        if (data.length) {
          setCompaniesOptions(data);
        }
      })
      .catch((error) => {
        if (error.response?.data === "auth/id-token-expired") {
          toast.error(
            "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
          );
          return;
        }
        console.error("Erro: ", error.message, " Status: ", error.code);
        toast.error("Não foi possível carregar as empresas");
      });
  };

  dispatch({ type: "SET_ACTION", value: openModal });

  function openModal(activity) {
    setActivity(activity);
    setActivityModal(true);
  }

  const handerOnClick = (step) => {
    if (result.reason === undefined && step === "won") {
      setWonModal(true);
    } else if (
      result.reason !== "" &&
      result.reason !== undefined &&
      step === "lost"
    ) {
      handleOpenModal();
      setLoseModal(true);
    }
  };

  let leadInfo = {
    id: leadID,
    stageID: fields.stageID,
    customerCompanyID: customerCompanyID,
    customerContactID: customerContactID,
  };


  useEffect(()=>{
    if (selectedPipeline){
      setLoadingStageOptions(true)
      setStageOptions([]);
      getAllStagesByPipelineID(selectedPipeline)
                .then(({ data }) => {
                  setStageOptions(data.sort((a, b) => a.order - b.order));                 
                  setLoadingStageOptions(false)
                })
                .catch((error) => {
                  if (error.response?.data === "auth/id-token-expired") {
                    toast.error(
                      "O seu acesso expirou! Atualizei a página ou faça novamente o login no sistema."
                    );
                    return;
                  }
                  console.error(
                    "Erro: ",
                    error.message,
                    " Status: ",
                    error.code
                  );
                  toast.error("Não foi possível carregar as etapas do funil");
                });                
    }
  },[selectedPipeline])

  return (
    <>
      <ProgressBar
        className="loading"
        loading={isLoading}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      <ProgressBar
        className="loading"
        loading={changingList}
        color="#545557"
        height="4px"
        style={{ position: "fixed", top: 0, left: 0 }}
      />
      {leadID && (
        <Modal
          className="modal-md"
          active={qualificationModal}
          closeModal={() => {
            setQualificationModal(false);
          }}
          content={
            <Qualification
              leadID={leadID}
              name={fields.name}
              status={qualificationModal}
              closeModal={() => {
                setQualificationModal(false);
              }}
            />
          }
        />
      )}

      {companyID && (
        <Modal
          className="modal-md"
          active={qualificationEditModal}
          closeModal={() => {
            setQualificationEditModal(false);
          }}
          content={
            <QualificationEdit
              companyID={companyID}
              leadID={leadID}
              name={fields.name}
              status={qualificationEditModal}
              closeModal={() => {
                setQualificationEditModal(false);
              }}
              setContinueLaterModal={setContinueLaterModal}
              setQualifiedModal={setQualifiedModal}
              setUnqualifiedModal={setUnqualifiedModal}
            />
          }
        />
      )}
      <Modal
        className="modal-sm"
        active={continueLaterModal}
        closeModal={() => {
          setContinueLaterModal(false);
        }}
        content={
          <ContinueLater
            status={continueLaterModal}
            leadID={leadID}
            closeModal={() => {
              setContinueLaterModal(false);
            }}
            refetch={listActivities}
          />
        }
      />

      <Modal
        className="modal-sm"
        active={qualifiedModal}
        closeModal={() => {
          setQualifiedModal(false);
        }}
        content={
          <Qualified
            leadID={leadID}
            closeModal={() => {
              setQualifiedModal(false);
            }}
            refetch={() => {
              listActivities();
              handleGetLead();
            }}
            teamMembers={teamMembersQualified}
            pipelineID={pipelineID}
            leadInfo={leadInfo}
          />
        }
      />
      <Modal
        className="unqualifiedModal modal-sm"
        active={unqualifiedModal}
        closeModal={() => {
          setUnqualifiedModal(false);
        }}
        content={
          <Unqualified
            closeModal={() => {
              setUnqualifiedModal(false);
            }}
          />
        }
      />

      {result.status === "pending" ? (
        <>
          <Modal
            className="wonModal modal-sm"
            active={wonModal}
            closeModal={() => {
              setWonModal(false);
              setIsLoading(false);
            }}
            content={
              <Won
                leadID={leadID}
                status={wonModal}
                closeModal={() => {
                  setWonModal(false);
                }}
                refetch={() => {
                  listActivities();
                  handleGetLead();
                }}
              />
            }
          />
          <Modal
            className="loseModal modal-sm"
            active={loseModal}
            closeModal={() => {
              setLoseModal(false);
              setIsLoading(false);
            }}
            content={
              <Lose
                leadID={leadID}
                status={loseModal}
                option={reasonOption}
                closeModal={() => {
                  setLoseModal(false);
                }}
                refetch={() => {
                  listActivities();
                  handleGetLead();
                }}
                status={loseModal}
              />
            }
          />
        </>
      ) : (
        <>
          <Modal
            className="wonModal modal-sm"
            active={wonModal}
            closeModal={() => {
              setWonModal(false);
              setIsLoading(false);
            }}
            content={
              <Won
                disabled={true}
                leadID={leadID}
                result={result}
                status={wonModal}
                closeModal={() => {
                  setWonModal(false);
                }}
              />
            }
          />
          <Modal
            className="loseModal modal-sm"
            active={loseModal}
            closeModal={() => {
              setLoseModal(false);
              setIsLoading(false);
            }}
            content={
              <Lose
                disabled={true}
                leadID={leadID}
                result={result}
                status={loseModal}
                option={reasonOption}
                closeModal={() => {
                  setLoseModal(false);
                }}
                status={loseModal}
              />
            }
          />
        </>
      )}

      <Modal
        className="modal-sm"
        active={activityModal}
        closeModal={() => {
          setActivityModal(false);
        }}
        content={
          <ActivityModal
            activity={activity}
            status={activityModal}
            leads={leads}
            closeModal={() => {
              setActivityModal(false);
            }}
            refetch={listActivities}
          />
        }
      />

      <div
        className="flex stageContent wrap fullWidth"
        style={{ marginBottom: "15px" }} /**defaultMarginBottom"*/
      >
        <div className="flexGrow flex">
          {result.status === "pending" || result.status === "lost"
            ? stages
                .filter((item) => {
                  /*item.step !== "lost" &&  item.step !== "won"*/
                  if (result.status === "pending") {
                    return item.step !== "lost" && item.step !== "won";
                  } else {
                    return item.step !== "won";
                  }
                })
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                  <StageHeader
                    isLoading={loadingSelectStage}
                    key={item.id}
                    text={item.description}
                    className="min pointer smallMarginBottom"
                    active={
                      stageHeaders === item.id || fields.stageID === item.id
                    }
                    onClick={() => {
                      if (item.step === "lost" && result.status === "lost") {
                        handerOnClick(item.step);
                      } else {
                        setStageHeaders(item.id);
                        handleCommonFields(item.id, "stageID");
                        selectStageOnClick(item);
                      }
                    }}
                  />
                ))
            : stages
                .sort((a, b) => a.order - b.order)
                .map((item) => (
                  <StageHeader
                    isLoading={loadingSelectStage}
                    key={item.id}
                    text={item.description}
                    className={
                      (result.reason !== "" &&
                        result.reason !== undefined &&
                        item.step === "lost") ||
                      (result.reason === undefined && item.step === "won")
                        ? "min pointer smallMarginBottom"
                        : "min smallMarginBottom"
                    }
                    active={
                      stageHeaders === item.id || fields.stageID === item.id
                    }
                    onClick={() => handerOnClick(item.step)}
                  />
                ))}
        </div>

        <div className="flex vCenter defaultMarginLeft">
          <Button
            text="Mover para outro funil"
            marginRight="2rem"
            width="160px"
            onClick={() => setEditPipelineModal(true)}
          />
          <Modal
            className="modal-sm"
            active={editPipelineModal}
            closeModal={() => setEditPipelineModal(false)}
            content={
              <>
              <h3>Escolha para qual funil deseja mover</h3>
              <div className="flex between" style={{marginTop: "25px"}}>
                <div >
                  <h4>Funíl</h4>
                  <Select
                    placeholder="Ver todos funis"
                    style={{ width: "280px", marginTop: "20px" }}
                    options={pipelineOptions}
                    valueOption="description"
                    value={pipelineID}
                    onChange={(value) => setSelectedPipeline(value)}
                  />
                </div>
                <div >
                  <h4>Estágio</h4>
                  <Select
                    disabled={!stageOptions.length}
                    placeholder={loadingStageOptions? "carregando": stageOptions.length ? "Ver todos estagios" : "- - - - -"}
                    style={{width: "280px", marginTop: "20px" }}
                    options={stageOptions.slice(0,-2)}
                    valueOption="description"
                    value={pipelineID}
                    onChange={(value) => setNewStage(value)}
                  />
                </div>
              </div>
              <div 
                style={{marginTop: "20px", display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                  text="Confirmar"
                  paddingHorizontal="20px"
                  background={newStage ? "#3DBCD8": "grey"}
                  className="flex center"
                  disabled={!newStage}
                  onClick={() => {
                    handleEditBusinessPipeline({pipelineId: selectedPipeline, stageId: newStage});
                  }}
                />

              </div>
              </>
            }
          />

          {result.status === "pending" ? (
            <>
              <Button
                text="Perdeu"
                paddingHorizontal="20px"
                background="#D99B9B"
                className="smallMarginRight"
                icon={require("../../png/thumb_down.png")}
                onClick={() => {
                  handleOpenModal();
                }}
              />
              <Button
                text="Ganhou"
                paddingHorizontal="20px"
                background="#3DBCD8"
                icon={require("../../png/thumb_up.png")}
                onClick={() => {
                  setWonModal(true);
                }}
              />
            </>
          ) : null}
        </div>
      </div>
      <div className="fullWidth flex changeDirection bodyContent ">
        <div className="flexGrow defaultMarginRight bodyColumn fullWidth">
          <div className="fullWidth flex between titleContent changeDirection">
            <div className="flex vCenter">
              {isDisabled ? (
                <h3 style={{ wordBreak: "break-all" }}>
                  {fields.name || "Nome não informado"}
                </h3>
              ) : (
                <Input
                  className="fullWidth"
                  placeholder={fields.name ? fields.name : "Nome do negócio"}
                  disabled={isDisabled}
                  isDefault={true}
                  value={fields.name}
                  onChange={(value) => handleCommonFields(value, "name")}
                  withoutMargin={true}
                />
              )}

              {isDisabled ? (
                <>
                  <Edit
                    width="20"
                    height="12"
                    className="defaultMarginLeft pointer flexShrinkNone"
                    onClick={() => {
                      setIsDisabled(false);
                    }}
                  />
                  {(window.onbeforeunload = null)}
                </>
              ) : (
                <>
                  <Button
                    isLoading={loadingPutLead}
                    className="smallMarginTop"
                    text="Salvar"
                    alignSelf="end"
                    paddingHorizontal="30px"
                    onClick={() => {
                      handlePutLead(fields);
                    }}
                  />
                  {
                    // Avisar o usuario caso saia do app
                    (window.onbeforeunload = function confirmExit() {
                      return toast.warning("Não esqueça de salvar.");
                    })
                  }
                </>
              )}
            </div>

            <div className="flex vCenter contentButtons defaultMarginLeft">
              {labelScore ? <Score text={labelScore} number={score} /> : null}
              <div className="flex column center">
                {isQualified !== null && !isQualified ? (
                  <Button
                    text="Qualificar"
                    paddingHorizontal="20px"
                    onClick={() => {
                      setQualificationEditModal(true);
                    }}
                  />
                ) : (
                  <Button
                    text="Ver qualificação"
                    paddingHorizontal="20px"
                    onClick={() => {
                      setQualificationModal(true);
                    }}
                  />
                )}
              </div>
              <div classname="flex column right">
                {isQualified ? (
                  <Edit
                    text="Editar qualificação"
                    width="20"
                    height="12"
                    className="defaultMarginLeft pointer flexShrinkNone"
                    onClick={() => {
                      setQualificationEditModal(true);
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>

          <div className="fullWidth flex center wrap defaultMarginTop contentInputs">
            {/* TODO: Implementar auto complete */}
            {/* <Input label="Empresa" className="noLimit" disabled={isDisabled} isDefault={true} value={fields.customerCompany.companyName} onChange={(value) => { handleCompany(`${value}`, "companyName") }} /> */}

            <div style={{ marginTop: "8px" }} className="flex column halfWidth">
              <label className="secondaryLabel">
                <span>Empresa</span>
              </label>
              <AutoComplete
                disabled={isDisabled}
                value={customerCompanyName}
                isBusinessPage={isBusinessPage}
                onChange={(event, option) => {
                  if (option && option.inputValue && isBusinessPage) {
                    setCustomerCompanyID("");
                    setCustomerCompanyName(option.inputValue);
                  } else {
                    if (option) {
                      setCustomerCompanyName(option.companyName);
                      setCustomerCompanyID(option.id);
                      setSegment(option.segment ? option.segment : "");
                      setSite(option.site ? option.site : "");
                      setEmail(option.email ? option.email : "");
                      setPhoneMain(option.phoneMain ? option.phoneMain : "");
                      setSocialNetwork(
                        option.socialNetwork ? option.socialNetwork : ""
                      );
                    }
                  }
                }}
                options={companiesOptions}
              />
            </div>
            <Select
              placeholder="Qual o segmento?"
              className="noLimit"
              value={segment}
              onChange={(value) => setSegment(value)}
              options={segmentsOptions}
              isDefault={true}
              label="Segmento de mercado"
              disabled={isDisabled}
            />
            <Input
              label="Site"
              className="noLimit"
              disabled={isDisabled}
              value={site}
              isDefault={true}
              onChange={(value) => setSite(value)}
            />
            <Input
              label="E-mail"
              className="noLimit"
              disabled={isDisabled}
              value={email}
              isDefault={true}
              onChange={(value) => setEmail(value)}
            />
            <InputMasked
              label="Telefone principal"
              className="noLimit"
              disabled={isDisabled}
              value={phoneMain}
              isDefault={true}
              onChange={(value) => setPhoneMain(value)}
              mask="(99) 99999-9999"
              maskChar={null}
            />
            <Input
              label="Rede social"
              className="noLimit"
              disabled={isDisabled}
              value={socialNetwork}
              isDefault={true}
              onChange={(value) => setSocialNetwork(value)}
            />

            <Hr
              className="fullWidth defaultMarginTop noLimit"
              background="#C9C9C9"
            />
            <div className="fullWidth flex wrap contentInputs center noLimit">
              <div
                style={{ marginTop: "8px" }}
                className="flex column halfWidth"
              >
                <label className="secondaryLabel">
                  <span>Contato</span>
                </label>
                <AutoComplete
                  disabled={isDisabled}
                  value={customerContactName}
                  isBusinessPage={isBusinessPage}
                  onChange={(event, option) => {
                    if (option && option.inputValue && isBusinessPage) {
                      setCustomerContactID("");
                      setCustomerContactName(option.inputValue);
                    } else {
                      if (option) {
                        setCustomerContactName(option.name);
                        setCustomerContactID(option.id);
                        setContactEmail(option.email ? option.email : "");
                        setContactPhoneMain(
                          option.phoneMain ? option.phoneMain : ""
                        );
                        setContactPhoneSecondary(
                          option.phoneSecondary ? option.phoneSecondary : ""
                        );
                        setContactWhatsApp(
                          option.whatsApp ? option.whatsApp : ""
                        );
                        setContactPosition(
                          option.position ? option.position : ""
                        );
                      }
                    }
                  }}
                  contactsOptions={contactsOptions}
                />
              </div>
              <Input
                label="Cargo"
                disabled={isDisabled}
                value={contactPosition}
                isDefault={true}
                onChange={(value) => setContactPosition(value)}
                className="noLimit"
              />
              <Input
                label="E-mail"
                className="noLimit"
                disabled={isDisabled}
                value={contactEmail}
                isDefault={true}
                onChange={(value) => setContactEmail(value)}
              />
              <InputMasked
                label="Telefone principal"
                className="noLimit"
                disabled={isDisabled}
                isDefault={true}
                value={contactPhoneMain}
                onChange={(value) => setContactPhoneMain(value)}
                mask="(99) 99999-9999"
                maskChar={null}
              />
              <InputMasked
                label="Telefone secundário"
                className="noLimit"
                disabled={isDisabled}
                value={contactPhoneSecondary}
                isDefault={true}
                onChange={(value) => setContactPhoneSecondary(value)}
                mask="(99) 99999-9999"
                maskChar={null}
              />
              <InputMasked
                label="Whatsapp"
                className="noLimit"
                disabled={isDisabled}
                value={contactWhatsApp}
                isDefault={true}
                onChange={(value) => setContactWhatsApp(value)}
                mask="(99) 99999-9999"
                maskChar={null}
              />
            </div>
            <Hr
              className="fullWidth defaultMarginTop noLimit"
              background="#C9C9C9"
            />
            {/* TODO: Implementar select quando Members tiver sido implementado */}
            <Select
              placeholder=" "
              label="Responsável"
              onChange={(value) => handleCommonFields(value, "memberProfileID")}
              options={teamMembers}
              valueOption="name"
              value={fields.memberProfileID}
              disabled={isDisabled}
              isDefault={true}
              className="noLimit"
            />

            <InputMasked
              label="Data prevista do negócio"
              placeholder={"00/00/0000"}
              className="noLimit"
              mask="99/99/9999"
              maskChar={null}
              disabled={isDisabled}
              value={fields.expectedDate}
              isDefault={true}
              onChange={(value) => {
                handleCommonFields(`${value}`, "expectedDate");
              }}
            />
            <Select
              placeholder="Qual é a origem?"
              className="noLimit"
              value={fields.leadOrigin}
              onChange={(value) => handleCommonFields(`${value}`, "leadOrigin")}
              options={sourcesOptions}
              isDefault={true}
              label="Origem do lead"
              disabled={isDisabled}
            />
            <Select
              placeholder="Quais são as dores?"
              className="noLimit"
              value={fields.pain}
              onChange={(value) => handleCommonFields(`${value}`, "pain")}
              options={painOptions}
              isDefault={true}
              label="Dores"
              disabled={isDisabled}
            />
            <InputCurrency
              label="Valor único"
              className="noLimit"
              disabled={isDisabled}
              value={fields.uniqueValue}
              isDefault={true}
              onChange={(value) => {
                handleCommonFields(`${value}`, "uniqueValue");
              }}
            />
            <InputCurrency
              label="Valor recorrente"
              className="noLimit"
              disabled={isDisabled}
              value={fields.recurringValue}
              isDefault={true}
              onChange={(value) => {
                handleCommonFields(`${value}`, "recurringValue");
              }}
            />

            {customFieldLabel && (
              <>
                <Hr
                  className="fullWidth defaultMarginTop noLimit"
                  background="#C9C9C9"
                />
                <Input
                  className="fullWidth"
                  label={customFieldLabel}
                  disabled={isDisabled}
                  value={fields.customField}
                  isDefault={true}
                  onChange={(value) => handleCommonFields(value, "customField")}
                />
                <Hr
                  className="fullWidth defaultMarginTop noLimit"
                  background="#C9C9C9"
                />
              </>
            )}

            <TextArea
              label="Observações"
              disabled={isDisabled}
              value={fields.observation}
              isDefault={true}
              onChange={(value) => {
                handleCommonFields(`${value}`, "observation");
              }}
              className="fullWidth noLimit"
            />
          </div>
          <div>
            <TextArea
              label="Comentário"
              disabled={false}
              value={comment}
              isDefault={true}
              onChange={(value) => setComment(value)}
              className="fullWidth defaultMarginBottom activityTextArea"
            ></TextArea>
            {!!comment.length && (
              <Button
                text="Salvar comentário"
                className="endSelf"
                paddingHorizontal="20px"
                onClick={() => saveComment(comment)}
              />
            )}
          </div>
          <div>
            <Comments
              title="Comentários"
              data={comments}
              className="defaultMarginTop"
              loading={loadingComments}
              refetch={(callback) => getComments(callback)}
            />
          </div>
        </div>

        <div className="flexGrow flex column defaultMarginLeft bodyColumn fullWidth">
          <TextArea
            label="Atividade"
            disabled={false}
            isBusinessPage={true}
            value={note.description}
            isDefault={true}
            controlled={true}
            selectedType={note.activityTypeID}
            setType={(value) =>
              handleNoteFields(`${value || ""}`, "activityTypeID")
            }
            onChange={(value) => handleNoteFields(`${value}`, "description")}
            className="fullWidth defaultMarginBottom activityTextArea"
          />
          <div className="fullWidth flex between">
            <div className="flex">
              <InputMasked
                label="Data"
                placeholder={"00/00/0000"}
                disabled={false}
                isDefault={true}
                value={note.date}
                onChange={(value) => {
                  handleNoteFields(`${value}`, "date");
                }}
                style={{ maxWidth: "100px" }}
                mask="99/99/9999"
                maskChar={null}
              />
              <InputHour
                label="Hora de início"
                placeholder={"00:00"}
                disabled={false}
                isDefault={true}
                value={note.startTime}
                onChange={(value) => {
                  handleNoteFields(`${value}`, "startTime");
                }}
                style={{ maxWidth: "100px" }}
                mask="99:99"
                maskChar={null}
              />
              <InputHour
                label="Hora de fim"
                placeholder={"00:00"}
                disabled={false}
                isDefault={true}
                value={note.endTime}
                onChange={(value) => {
                  handleNoteFields(`${value}`, "endTime");
                }}
                style={{ maxWidth: "100px" }}
                mask="99:99"
                maskChar={null}
              />
            </div>
            <Button
              text="Salvar"
              className="endSelf"
              isLoading={loadingPostActivity}
              paddingHorizontal="20px"
              onClick={() => handlePostActivity(note)}
            />
          </div>

          <Activities
            title="Próximas atividades"
            data={nextActivities}
            className="defaultMarginTop"
            loading={loadingListActivities}
            refetch={listActivities}
          />
          <Activities
            title="Histórico de Atividades"
            isHistory={true}
            data={historyActivities}
            className="defaultMarginTop"
            loading={loadingListActivities}
            refetch={(callback) => listActivities(callback)}
          />

          <div>
            <Events
              title="Histórico da Lead"
              data={events}
              className="defaultMarginTop"
              loading={loadingEvents}
              refetch={(callback) => getEvents(callback)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessDetailPage;
