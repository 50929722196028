/* eslint-disable */
import React, { useState } from "react";
import Control from "../../../Control";
import { getMonthString } from "../../../../script/util";

function History({ data }) {
  let { created, comment, userName } = data;
  let initialsMonth;
  let time;

  if (created) {
    let month;
    created = new Date(created);
    month = created.getMonth();
    time = created.toLocaleTimeString("pt-BR");
    initialsMonth = getMonthString(month).toUpperCase();
  }

  // TODO @Denis pegar do informations

  return (
    <div className="flex vCenter defaultMarginTop">
      <div className="flex column center defaultMarginRight timelineItem">
        <span className="dayActivity">{created && created.getDate()}</span>
        <span className="monthActivity">
          {initialsMonth && initialsMonth.substr(0, 3)}
        </span>
        <span className="monthActivity">{time}</span>
      </div>
      <div className="flex column center">
        <span className="monthActivity">{data.userName}</span>
      </div>
      <div className="flex nowrap fullWidth activity">
        <div className="flex flexGrow">
          <div className="flex column smallMarginLeft">
            <p className="flex vCenter">
              <span style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                {data.comment}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
