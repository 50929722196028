
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import CommonRoute from './CommonRoute';
import PrivateRoute from './PrivateRoute';
import HomePage from '../pages/HomePage';
import CreateAccountPage from '../pages/CreateAccountPage';
import SucessPage from '../pages/SucessPage';
import RecoverPasswordPage from '../pages/RecoverPasswordPage';
import CreateNewPasswordPage from '../pages/CreateNewPasswordPage';
import ActivitiesPage from '../pages/ActivitiesPage';
import RegisteredUsersPage from '../pages/RegisteredUsersPage';
import DataUserPage from '../pages/DataUserPage';
import DataCompanyPage from '../pages/DataCompanyPage';
import TeamsPage from '../pages/TeamsPage';
import TiposAtividadesPage from '../pages/TiposAtividadesPage';
import BusinessPage from '../pages/BusinessPage';
import BusinessDetailPage from '../pages/BusinessDetailPage';
import CompaniesDetailPage from '../pages/CompaniesDetailPage';
import ContactsDetailPage from '../pages/ContactsDetailPage';
import ContactsPage from '../pages/ContactsPage';
import CompaniesPage from '../pages/CompaniesPage';
import QualificationFlowPage from '../pages/QualificationFlowPage';
import ConfigurationsPage from '../pages/ConfigurationsPage';
import PipelineAndCadencePage from '../pages/PipelineAndCadencePage';
import CustomFieldsPage from '../pages/CustomFieldsPage';
import SearchResultPage from '../pages/SearchResultPage';
import StatusPage from "../pages/StatusPage";
import ReleasesPage from "../pages/ReleasesPage";
import DashboardPage from "../pages/Dashboard";
import { ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER, ROLE_ADMIN } from "../utils"

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <CommonRoute component={<HomePage />} path="/" exact />
        {/* Autenticação */}
        <CommonRoute component={<CreateAccountPage />} path="/criar-conta" redirect="/sucesso" />
        <CommonRoute component={<RecoverPasswordPage />} path="/recuperar-senha" />
        <CommonRoute component={<CreateNewPasswordPage />} path="/nova-senha" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<SucessPage />} path="/sucesso" />
        {/* Roda de Testes **/}
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<StatusPage />} path="/status" exact />
        {/* Menu principal */}
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<ActivitiesPage hasBase={true} />} path="/atividades" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<ContactsPage hasBase={true} />} path="/contatos" exact />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<ContactsDetailPage hasBase={true} />} path="/contatos/:id" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<CompaniesPage hasBase={true} />} path="/empresas" exact />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<CompaniesDetailPage hasBase={true} />} path="/empresas/:id" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<BusinessPage hasBase={true} />} path="/negocios" exact />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<BusinessDetailPage hasBase={true} />} path="/negocios/:id" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<ConfigurationsPage hasBase={true} />} exact path="/configuracoes" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<DataCompanyPage hasBase={true} />} path="/configuracoes/perfil-empresa" />
        {/*<PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<ReleasesPage hasBase={true} />} path="/configuracoes/releases" />*/}
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<TeamsPage hasBase={true} />} path="/configuracoes/equipe" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<TiposAtividadesPage hasBase={true} />} path="/configuracoes/tipos-atividades" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<CustomFieldsPage hasBase={true} />} path="/configuracoes/campos-personalizados" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<QualificationFlowPage hasBase={true} />} path="/configuracoes/fluxo-qualificacao" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<PipelineAndCadencePage hasBase={true} />} path="/configuracoes/funil-cadencia" /> {/* TODO Renomear para PipelineAndCadencePage */}
        <PrivateRoute roles={[ROLE_ADMIN]} component={<RegisteredUsersPage hasBase={true} />} path="/configuracoes/usuarios" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<DataUserPage hasBase={true} />} path="/perfil-usuario" />
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<SearchResultPage hasBase={true} />} path="/busca" />
        {/** DASHBOARD ROUTE */}
        <PrivateRoute roles={[ROLE_ADMIN, ROLE_MANAGER, ROLE_SELLER, ROLE_PRE_SELLER]} component={<DashboardPage hasBase={true} />} path="/dashboard" exact />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;